<template>
  <v-card>
    <v-card-title class="align-start">
      New Account
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="data.pb"
                label="PB #"
                tabindex="1"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data.name"
                :rules="rules.nameRules"
                :counter="50"
                label="Name"
                tabindex="2"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="data.phone"
                :rules="[rules.required]"
                label="Phone Number"
                tabindex="3"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="data.postal_code"
                :rules="[rules.required]"
                label="Postal Code"
                tabindex="4"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data.company"
                :rules="rules.companyRules"
                :counter="50"
                label="Company"
                tabindex="5"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="data.address"
                label="Address"
                tabindex="6"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="data.address_2"
                label="Address 2"
                tabindex="7"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="data.city"
                label="City"
                tabindex="8"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="data.state"
                label="State"
                tabindex="9"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data.email"
                :rules="rules.emailRules"
                label="E-mail"
                tabindex="10"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="data.password"
                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show_password ? 'text' : 'password'"
                label="Password"
                hint="At least 8 characters"
                counter
                tabindex="11"
                @click:append="show_password = !show_password"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="data.password_confirmation"
                :append-icon="show_password_confirmation ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show_password_confirmation ? 'text' : 'password'"
                label="Confirm Password"
                tabindex="12"
                @click:append="show_password_confirmation = !show_password_confirmation"
                @keydown.enter="saveUser"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-btn color="primary" tabindex="13" @click="saveUser">
              Save
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    data: () => ({
      valid: false,
      data: {
        pb: '',
        name: '',
        phone: '',
        postal_code: '',
        email: '',
        company: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        password: '',
        password_confirmation: '',
      },
      show_password: false,
      show_password_confirmation: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        nameRules: [
          v => !!v || 'Name is required',
          // v => v.length <= 10 || 'Name must be less than 10 characters',
        ],
        companyRules: [
          v => !!v || 'Company is required',
          v => v.length <= 50 || 'Company must be less than 50 characters',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          // v => /.+@(?!usps\.gov$)(.+)$/.test(v) || 'E-mail must be valid',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
          v => /.+@(?!usps\.gov)(.+)/.test(v) || 'Must not be an USPS email',
        ],
      },
    }),
    methods: {
      saveUser () {
        let color = 'error'
        let msg = 'Data are still invalid!'

        this.$refs.form.validate()

        // try {
        //   if (this.valid) {
        //     const token = this.$store.state.token
        //     const url = process.env.VUE_APP_API_URL

        //     const { data: { message } } = await axios
        //       .patch(
        //         `${url}/api/user/${this.user_id}`,
        //         this.data,
        //         { headers: { Authorization: `Bearer ${token}` } }
        //       )

        //     color = 'success'
        //     msg = message

        //     this.$router.push({ name: 'accounts-show', params: { id: this.user_id } })
        //   }
        // } catch (error) {
        //   console.log(error);
        // }

        if (this.valid) {
          color = 'success'
          msg = 'USPS successfully created'

          this.$router.push({ name: 'accounts' })
        }

        this.$root.snackbar.show({ message: msg, color: color });
      },
    }
  }
</script>
